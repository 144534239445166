import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Footer from "../components/footer/footer"

import "./index.scss"
import "./services.scss"

import ImgLogo1 from "../images/techstack/figma.svg"
import ImgLogo2 from "../images/techstack/adobe-creative-suite.svg"
import ImgLogo3 from "../images/techstack/trello.svg"
import ImgLogo4 from "../images/techstack/toggl.svg"
import ImgLogo5 from "../images/techstack/slack.svg"
import ImgLogo6 from "../images/techstack/powerbi.svg"

import ImgLogo7 from "../images/techstack/php.svg"
import ImgLogo8 from "../images/techstack/nette.svg"
import ImgLogo9 from "../images/techstack/mysql.svg"
import ImgLogo10 from "../images/techstack/elasticsearch.svg"
import ImgLogo11 from "../images/techstack/docker.svg"
import ImgLogo12 from "../images/techstack/kubernetes.svg"
import ImgLogo13 from "../images/techstack/angular.svg"
import ImgLogo14 from "../images/techstack/bootstrap.svg"
import ImgLogo15 from "../images/techstack/github.svg"
import ImgLogo16 from "../images/techstack/bitbucket.svg"
import ImgLogo17 from "../images/techstack/gitlab.svg"

import ImgLogo18 from "../images/techstack/sentry.svg"
import ImgLogo19 from "../images/techstack/uptimerobot.svg"
import ImgLogo20 from "../images/techstack/aws.svg"
import ImgLogo21 from "../images/techstack/selenium.svg"
import ImgLogo22 from "../images/techstack/owasp.svg"

import VideoBg from "../videos/ftrs-video.mp4"

const Services = () => (

    <Layout>
        <SEO title="Products and Services" description="Market-leading products and services" />

		<div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content ftrs-services">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Market-leading products and&nbsp;services</h1>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-services__paragraph text-basic mt-5">Creating a&nbsp;valuable product might be a&nbsp;challenging path, full of&nbsp;input validation, prototyping, design, product and&nbsp;software architecture, UX/UI, product maintenance and&nbsp;optimisation.</p>
            <p data-sal="fade" data-sal-duration="1000"><strong>We are ready to&nbsp;support you on&nbsp;the&nbsp;way to&nbsp;easily achieve your goals.</strong></p>

		</Container>

		<div className="ftrs-services__box-stage">
			<Container>

				<Row>
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Design experience</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							We can help you plan and&nbsp;organize your projects. We&nbsp;are a&nbsp;team of&nbsp;experienced professionals who push each project to&nbsp;new frontiers. We&nbsp;can help you prioritize and&nbsp;plan your exciting digital product.
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>Product architecture and&nbsp;design</li>
							<li>Understanding your business needs</li>
							<li>Innovation and&nbsp;creativity</li>
							<li>Planning and&nbsp;prioritization</li>
							<li>Project management</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo1} alt="Figma" />
								</div>
								<div className="ftrs-techstack__header">Figma</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo3} alt="Trello" />
								</div>
								<div className="ftrs-techstack__header">Trello</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo4} alt="Toggl" />
								</div>
								<div className="ftrs-techstack__header">Toggl</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo5} alt="Slack" />
								</div>
								<div className="ftrs-techstack__header">Slack</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo6} alt="Power BI" />
								</div>
								<div className="ftrs-techstack__header">Power BI</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo2} alt="Adobe Creative Suite" />
								</div>
								<div className="ftrs-techstack__header">Creative Suite</div>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="mt4">
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Precise handicraft</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							Our team of experienced developers will build your app with&nbsp;respect to&nbsp;your vision, deadlines and&nbsp;security standards. Using the&nbsp;latest technology and&nbsp;best practices, we&nbsp;will develop a&nbsp;quality product that your customers will love using. 
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>Web and mobile app development</li>
							<li>Latest tech stack</li>
							<li>Quality assurance</li>
							<li>Focus on&nbsp;security</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo7} alt="PHP" />
								</div>
								<div className="ftrs-techstack__header">PHP</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo8} alt="Nette" />
								</div>
								<div className="ftrs-techstack__header">Nette</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo9} alt="MySQL" />
								</div>
								<div className="ftrs-techstack__header">MySQL</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo10} alt="Elasticsearch" />
								</div>
								<div className="ftrs-techstack__header">Elasticsearch</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo11} alt="Docker" />
								</div>
								<div className="ftrs-techstack__header">Docker</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo12} alt="Kubernetes" />
								</div>
								<div className="ftrs-techstack__header">Kubernetes</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo13} alt="AngularJS" />
								</div>
								<div className="ftrs-techstack__header">AngularJS</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo14} alt="Bootstrap" />
								</div>
								<div className="ftrs-techstack__header">Bootstrap</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo15} alt="Github" />
								</div>
								<div className="ftrs-techstack__header">Github</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo16} alt="Bitbucket" />
								</div>
								<div className="ftrs-techstack__header">Bitbucket</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo17} alt="Gitlab" />
								</div>
								<div className="ftrs-techstack__header">Gitlab</div>
							</div>
						</div>
					</Col>
				</Row>


				<Row className="mt4">
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Digital partnering</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							Your product launch is not the&nbsp;end point. An&nbsp;awesome digital product needs constant development and&nbsp;support, and&nbsp;our&nbsp;team is passionate about pushing your digital products to&nbsp;new heights. Our&nbsp;DevOps engineers prefer working with cloud services that&nbsp;are secure and&nbsp;scalable.
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>Ongoing digital support</li>
							<li>Maintenance</li>
							<li>Devops</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo20} alt="AWS" />
								</div>
								<div className="ftrs-techstack__header">AWS</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo18} alt="Sentry" />
								</div>
								<div className="ftrs-techstack__header">Sentry</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo19} alt="Uptimerobot" />
								</div>
								<div className="ftrs-techstack__header">Uptimerobot</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo21} alt="Selenium" />
								</div>
								<div className="ftrs-techstack__header">Selenium</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo22} alt="Owasp" />
								</div>
								<div className="ftrs-techstack__header">Owasp</div>
							</div>
						</div>
					</Col>
				</Row>


			</Container>
		</div>

		<Container>

			<h2 className="ftrs-h2 ftrs-h2--big">What are we best at?</h2>

            <Row>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Industries</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Banking</li>
                        <li>Automotive</li>
                        <li>Insurance</li>
                        <li>Telco</li>
                        <li>Retail</li>
                        <li>Smart cities</li>
                    </ul>
                </Col>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Categories</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Content Management</li>
                        <li>Market Research</li>
                        <li>Survey and Reporting</li>
                        <li>Business Intelligence</li>
                        <li>Competitive Intelligence</li>
                        <li>Customer experience</li>
                    </ul>
                </Col>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Expertise</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Data analytics</li>
                        <li>Complex cross organizational communication</li>
                        <li>Smart emailing</li>
                        <li>User experience design</li>
                    </ul>
                </Col>
            </Row>

        </Container>

		<Footer></Footer>

    </Layout>

)

export default Services 