import { Link } from "gatsby"
import React from "react"

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import "./header.scss"

import ImgFtrsLogoWhite from "../../images/ftrs-logo-white.svg"

function ftrsHeaderMobileBg() {
    var ftrsHeaderMobileElement = document.getElementById("ftrsHeaderMobile");
    if(ftrsHeaderMobileElement.classList.contains("navbar--mobile")) {
        ftrsHeaderMobileElement.classList.remove("navbar--mobile");
    }
    else {
        ftrsHeaderMobileElement.classList.add("navbar--mobile");
    }
}

const Header = () => (

    <header className="ftrs-header">

        <Navbar className="ftrs-header__navbar" id="ftrsHeaderMobile" expand="lg">
            <Link to="/" className="ftrs-header__logo">
                <img
                    loading="lazy"
                    src={ImgFtrsLogoWhite}
                    height="40"
                    width="88"
                    className="ftrs-header__logo-image"
                    alt="Future Rockstars"
                />
            </Link>
            <Navbar.Toggle onClick={ftrsHeaderMobileBg} />
            <Navbar.Collapse>
                <Nav>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/">Home</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/services" partiallyActive={true}>Services</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/our-work" partiallyActive={true}>Our work</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/about-us" partiallyActive={true}>About us</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/careers" partiallyActive={true}>Careers</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/contact" partiallyActive={true}>Contact</Link>
                    <Link className="ftrs-header__link ftrs-header__link--en ftrs-header__link--active-lang" to="/">EN</Link>
                    <Link className="ftrs-header__link ftrs-header__link--cs" to="/cs">CZ</Link>
                    <Link className="ftrs-header__link ftrs-header__link--de" to="/de">DE</Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    </header>

)

export default Header